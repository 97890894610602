export default {
    methods: {
        getActualityContext(id, key) {
            const item = this.getContexts().find((o) => o.id === id);
            if (item) {
                return item[key];
            } else {
                console.warn(`Unknown actuality context: "${id}"`);
                return '';
            }
        },

        getActualityAttachmentImages(actuality) {
            if (Array.isArray(actuality.attachments)) {
                return actuality.attachments.filter((a) => a.type === 'image');
            } else {
                return [];
            }
        },

        getActualityAttachmentYoutubeLinks(actuality) {
            if (Array.isArray(actuality.attachments)) {
                return actuality.attachments.filter((a) => a.type === 'link' && a.targetFileName.match(/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/));
            } else {
                return [];
            }
        },

        getActualityAttachmentVideoLinks(actuality) {
            if (Array.isArray(actuality.attachments)) {
                return actuality.attachments.filter((a) => a.type === 'link' && a.targetFileName.match(/(http:|https:)?\/\/.*?\.mp4/));
            } else {
                return [];
            }
        },

        getActualityAttachmentDocuments(actuality) {
            if (Array.isArray(actuality.attachments)) {
                return actuality.attachments.filter((a) => a.type === 'document');
            } else {
                return [];
            }
        },

        getAttachmentUrl(attachment) {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/actualities/attachments/${attachment.id}`;
        },

        getYoutubeEmbedUrl(url) {
            const regex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            const matches = regex.exec(url);
            const videoId = matches[7];
            return `https://www.youtube.com/embed/${videoId}`;
        },

        getContexts() {
            const allowedUserTypes = this.getConfig('actualities.allowed_user_types_for_generics_management');
            const genericsEnabled = this.$store.state.application.user.type === 'user'
                && allowedUserTypes.includes(this.$store.state.application.user.targetType);

            return [
                { id: 'buyers', label: 'Générique', disabled: !genericsEnabled },
                { id: 'buyers-programs', label: 'Programme(s)' },
                { id: 'buyers-programs-groups', label: 'Groupe(s) de programmes' },
                { id: 'buyers-contacts', label: 'Acquéreur(s)' }
            ];
        },

        isAllowedToUpdateGenerics(actualityContext) {
            const allowedUserTypes = this.getConfig('actualities.allowed_user_types_for_generics_management');

            return actualityContext !== 'buyers' || (
                this.$store.state.application.user.type === 'user'
                && allowedUserTypes.includes(this.$store.state.application.user.targetType)
            );
        }
    }
};
