<template>
    <v-autocomplete v-model="selectedGroups" :items="displayGroups" :loading="isLoading" :disabled="disabled" item-value="id" clear-icon="far fa-times-circle" :search-input.sync="search" @focus="$emit('focus')" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedGroups)" :multiple="multiple" :label="label" :placeholder="placeholder" no-data-text="Commencez à taper pour lancer une recherche" background-color="white" hide-details no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip v-if="data.index === 0" small label>
                {{ data.item.name }}
            </v-chip>

            <span v-if="data.index === 1" class="grey--text text-caption">
                (+{{ selectedGroups.length - 1 }} autres)
            </span>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    {{ item.name }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: 'ProgramPartnerGroupAutocomplete',

    props: {
        value: { required: true },
        label: { type: String },
        placeholder: { type: String },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        noDynamicSearch: { type: Boolean, default: false },
        initProgramPartnerGroups: { type: Array }
    },

    data: () => ({
        selectedGroups: null,
        groups: [],
        displayGroups: [],
        isLoading: false,
        search: null,
        searchTimeout: null
    }),

    watch: {
        value() {
            this.selectedPartners = this.value;
        },
        
        search(search) {
            if (this.noDynamicSearch) {
                if (typeof search !== 'string' || search.length.length < 3) {
                    this.displayGroups = this.groups;
                } else {
                    this.displayGroups = this.groups.filter((p) => {
                        return p.title.toLowerCase().includes(search.toLocaleLowerCase());
                    });
                }
                return;
            }

            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                if (typeof search !== 'string' || search.length.length < 3) {
                    return;
                }
                if (this.isLoading) {
                    return;
                }

                this.isLoading = true;
                const query = {
                    limit: 5,
                    quickSearch: search
                };
                this.fetchGroups(query);
            }, 500);
        },

        initProgramPartnerGroups() {
            this.displayGroups = this.initProgramPartnerGroups;
        }
    },

    computed: {
        noDataText() {
            if (this.noDynamicSearch) {
                return 'Aucun groupe disponible';
            } else {
                return 'Commencez à taper pour lancer une recherche';
            }
        }
    },

    methods: {
        async fetchGroups(query) {
            try {
                this.isLoading = true;
                const { groups } = await this.repos.programPartnerGroups.getGroups(query);
                this.groups = groups;
                this.displayGroups = groups;
            } catch (err) {
                console.error(err);
            } finally {
                this.isLoading = false;
            }
        },

        reset() {
            this.selectedGroups = [];
        }
    },

    created() {
        this.selectedGroups = this.value;
        if ((Array.isArray(this.selectedGroups) && this.selectedGroups.length > 0) || typeof this.selectedGroups === 'number') {
            const groups = Array.isArray(this.selectedGroups) ? this.selectedGroups : [this.selectedGroups];
            this.fetchGroups({ groups });
        }
        if (this.initProgramPartnerGroups) {
            this.displayGroups = this.initProgramPartnerGroups;
        }
    }
};
</script>