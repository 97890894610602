<template>
    <v-dialog v-model="isOpen" width="500">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Supprimer un document
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                Confirmez-vous la suppression de ce document?
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit" color="error" small>
                    Supprimer
                </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small>
                    Annuler
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'RemoveAttachmentDialog',

    props: {
        attachment: { type: Object, required: true }
    },

    data: () => ({
        isOpen: false
    }),

    methods: {
        async submit() {
            try {
                this.setLoading(true);

                const { success, err } = await this.repos.actualities.removeAttachment(this.attachment.id);
                if (success) {
                    this.isOpen = false;
                    this.$notify({
                        title: 'Information',
                        text: 'L\'image a bien été supprimée',
                        type: 'success'
                    });
                    this.$emit('deleted');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la supression de l\'image',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>
